// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import Loadable from 'shared/components/Loadable';
import LoginStore from 'react/utils/login';
import Http from 'react/utils/http';
import Intercom from 'react/utils/intercom';
import preLoginRoutes from './PreLogin/routes';

const Login = Loadable({
    loader: () => import(/* webpackPrefetch: true */ './PreLogin/Login')
});

const Activation = Loadable({
    loader: () => import('./PreLogin/Activation')
});

const PasswordReset = Loadable({
    loader: () => import('./PreLogin/PasswordReset')
});

const LoggedIn = Loadable({
    loader: () => import(/* webpackPrefetch: true */ './LoggedIn')
});

export default class App extends React.Component {
    constructor(props) {
        super(props);
        props.history.listen(this.onRouteChange);
        addStagingOrLocalPrefixToTitle();
        getAppVersion((version) => {
            console.info(`Current version (etag): ${version}`);
            this.appVersion = version;
        });
    }

    appVersion;

    onRouteChange = (location, action) => {
        if (action === 'PUSH') {
            Http.cancelAllRequests();
        }
        if (!LoginStore.isImpersonated()) {
            Intercom.track('Viewed Page', { path: location.pathname });
            Intercom.updateUrl();
        }
        getAppVersion((version) => {
            if (version !== this.appVersion) {
                window.location.reload();
            }
        });
    };

    render() {
        return (
            <ConnectedRouter history={this.props.history}>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route 
                        path={preLoginRoutes.activationRoute.path} 
                        render={(props) => <Activation {...props} token={props.match.params.token} />}
                    />
                    <Route 
                        path={preLoginRoutes.passwordResetRoute.path}
                        render={(props) => <PasswordReset {...props} token={props.match.params.token} />}
                    />
                    <Route path="/" render={(props) => (
                        LoginStore.isLoggedIn() ? (
                            <LoggedIn router={props} />
                        ) : (
                            <Login {...props} />
                        )
                    )} />
                </Switch>
            </ConnectedRouter>
        );
    }
}

function getAppVersion(callback) {
    // We use etag of the index.html file to identify the current app version.
    // Our backend express server generates weak etag (using CRC32) on static assets, which is fairly performant.
    const INDEX_HTML_PATH = '/index.html';
    Http.head(INDEX_HTML_PATH, {}, {}, (result, errmsg, resp) => {
        callback(resp.headers.etag);
    });
}

function addStagingOrLocalPrefixToTitle() {
    let prefix = '';
    if (isStaging()) {
        prefix = '(STAGING)';
    } else if (isLocal()) {
        prefix = '(LOCAL)';
    } else {
        return;
    }
    if (!document.title.includes(prefix)) {
        // Extract the base title (everything after any existing prefix)
        const baseTitle = document.title.replace(/^\([^)]*\)\s*/, '');
        document.title = `${prefix} ${baseTitle}`;
    }
}

function isLocal() {
    const subDomain = getSubdomain();
    return (
        subDomain.includes('localhost')
        || subDomain === '192'
        || subDomain === '172'
    );
}

function isStaging() {
    return getSubdomain().includes('staging');
}

function getSubdomain() {
    const hostnameParts = window.location.hostname.split('.');
    return hostnameParts[0].toLowerCase();
}
